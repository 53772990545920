import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { useMutation, useQuery } from "@apollo/client";
import { GET_EMAILPARSERS } from "./emailparsers.query";
import AddEmailParser from "src/components/AddEmailParser/AddEmailParser";
import EditEmailParser from "src/components/EditEmailParser/EditEmailParser";
import { ThemeContext } from "src/helpers/themeContext";
import { DELETE_PARSER } from "./deleteQuery";
import SweetAlert from "react-bootstrap-sweetalert";
import DeleteModal from "src/components/deleteModal/deleteModal";

const EmailParsers = () => {
  const [tableData, setTableData] = useState<any>([]);
  const [emailParserModal, setEmailParserModal] = useState(false);
  const [editEmailParserModal, setEditEmailParserModal] = useState(false);
  const [selectedEmailParser, setSelectedEmailParser] = useState(false);

  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const Theme = useContext(ThemeContext);
  const [deleteParser] = useMutation(DELETE_PARSER);
  const [deleteModal, setDeleteModal] = useState(false);

  const columns = [
    {
      dataField: "Id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "OrgName",
      text: "Org Name",
      sort: true,
    },
    {
      dataField: "APIAccountName",
      text: "API Name",
      sort: true,
    },
    {
      dataField: "EmailAddress",
      text: "Email Address",
      sort: true,
    },
    {
      dataField: "ParserType",
      text: "Parser Type",
      sort: true,
    },
    {
      dataField: "TemplateName",
      text: "Template Name",
      sort: true,
    },
    {
      dataField: "ForwardingEmail",
      text: "Forwarding Email",
      sort: true,
    },
    {
      dataField: "whenToForward",
      text: "When To Forward",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, row: any) => (
        <React.Fragment>
          <UncontrolledDropdown>
            <DropdownToggle tag="button" className="btn btn-light btn-sm">
              <i className="uil uil-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={() => {
                  onEdit(row);
                }}
              >
                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                Edit
              </DropdownItem>
              <DropdownItem onClick={() => setDeleteModal(row)}>
                <i className="mdi mdi-delete font-size-16 me-1"></i>
                Delete Parser
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </React.Fragment>
      ),
    },
  ];

  const handleDelete = async (user: any) => {
    setDeleteModal(false);
    await deleteParser({
      variables: {
        id: user.Id,
        userId: localStorage.getItem("user_id"),
        time: new Date(Date.now()).toISOString(),
      },
    });
    setsuccess_dlg(true);
    setdynamic_description(
      "Item will not be available to the user now, it will be permanently deleted in 60 days"
    );
    refetch();
  };

  const onEdit = (row: any) => {
    setEditEmailParserModal(!editEmailParserModal);
    setSelectedEmailParser(row);
  };
  const defaultSorted: any = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const selectRow: any = {
    mode: "checkbox",
  };
  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  };
  const { SearchBar } = Search;

  const { data, refetch } = useQuery(GET_EMAILPARSERS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data && data.msgbox_EmailParserConfig) {
      var tmpData = data.msgbox_EmailParserConfig
        .filter((item: any) => item.Organisation && item.APIAccount)
        .map(function (item: {
          EmailParserConfigId: number;
          EmailAddress: string;
          ParserType: string;
          DoNotForwardIfDeliveredOK: boolean;
          DoNotForwardIfReadOK: boolean;
          DoNotForwardIfRepliedTo: boolean;
          DoNotForwardIfSentOK: boolean;
          Organisation: { Name: string };
          Template: { Name: string };
          TemplateId: number;
          ForwardingEmail: { Name: string };
          APIAccount: { Name: string; APIAccountId: number };
        }) {
          let whenToForward = "Always forward";
          if (item.DoNotForwardIfDeliveredOK)
            whenToForward = "Dont forward if delivered";
          if (item.DoNotForwardIfRepliedTo)
            whenToForward = "Dont forward if customer replied";
          if (item.DoNotForwardIfSentOK)
            whenToForward = "Dont forward if successfully sent";
          if (item.DoNotForwardIfReadOK)
            whenToForward = "Dont forward if customer read";
          return {
            Id: item.EmailParserConfigId,
            APIAccountName: item.APIAccount.Name,
            APIAccountId: item.APIAccount.APIAccountId,
            OrgName: item.Organisation.Name,
            EmailAddress: item.EmailAddress,
            TemplateName: item.Template.Name,
            templateId: item.TemplateId,
            ParserType: item.ParserType,
            DoNotForwardIfDeliveredOK: item.DoNotForwardIfDeliveredOK,
            DoNotForwardIfReadOK: item.DoNotForwardIfReadOK,
            DoNotForwardIfRepliedTo: item.DoNotForwardIfRepliedTo,
            DoNotForwardIfSentOK: item.DoNotForwardIfSentOK,
            whenToForward: whenToForward,
            ForwardingEmail: item.ForwardingEmail,
          };
        });
      setTableData(tmpData);
    }
  }, [data]);

  const toggleAddEmailParserModal = () => {
    setEmailParserModal(!emailParserModal);
  };

  const handleUserClicks = () => {
    toggleAddEmailParserModal();
  };

  const refetchData = () => {
    refetch();
  };

  return (
    <React.Fragment>
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <DeleteModal
        isOpen={deleteModal !== false}
        cancel={() => setDeleteModal(false)}
        submit={() => handleDelete(deleteModal)}
      />

      <Card>
        <CardHeader>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <h4 className="card-title">All Email Parsers</h4>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                <div>
                  <Link to="#" className="btn btn-light" onClick={refetchData}>
                    <i className="uil uil-refresh me-1"></i> Refresh
                  </Link>
                </div>
                <div>
                  <Link
                    to="#"
                    className="btn btn-light"
                    onClick={handleUserClicks}
                  >
                    <i className="uil uil-plus me-1"></i> Add New
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            // columns={columns}
            // data={productData}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="Id"
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            // responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={selectRow}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                          <EditEmailParser
                            modal={editEmailParserModal}
                            setModal={setEditEmailParserModal}
                            data={selectedEmailParser}
                            refresh={refetchData}
                          />
                          <AddEmailParser
                            modal={emailParserModal}
                            setModal={setEmailParserModal}
                            tableData={tableData}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default EmailParsers;
