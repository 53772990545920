import { gql } from "@apollo/client";

export const GET_TEMPLATES = gql`
  query MyQuery($apiAccountId: Int!) {
    msgbox_Template(
      where: {
        APIAccount: { _eq: $apiAccountId }
        Status: { _eq: Approved }
        IsDeleted: { _eq: false }
      }
      order_by: { Name: asc }
    ) {
      Name
      TemplateId
      TemplateText
    }
  }
`;

export const GET_PARSERTYPES = gql`
  query MyQuery {
    msgbox_EmailParserType {
      Name
    }
  }
`;

export const MUTATE_EMAILPARSERS = gql`
  mutation MyMutation(
    $_eq: Int!
    $DoNotForwardIfRepliedTo: Boolean
    $DoNotForwardIfSentOK: Boolean
    $DoNotForwardIfReadOK: Boolean
    $DoNotForwardIfDeliveredOK: Boolean
    $ForwardingEmail: String
    $ParserType: String!
    $TemplateId: Int!
  ) {
    update_msgbox_EmailParserConfig(
      where: { EmailParserConfigId: { _eq: $_eq } }
      _set: {
        DoNotForwardIfReadOK: $DoNotForwardIfReadOK
        DoNotForwardIfDeliveredOK: $DoNotForwardIfDeliveredOK
        DoNotForwardIfSentOK: $DoNotForwardIfSentOK
        DoNotForwardIfRepliedTo: $DoNotForwardIfRepliedTo
        ForwardingEmail: $ForwardingEmail
        ParserType: $ParserType
        TemplateId: $TemplateId
      }
    ) {
      affected_rows
    }
  }
`;

export const GET_MAPPING = gql`
  query MyQuery(
    $parserType: String!
    $templateId: Int!
    $emailAddress: String!
  ) {
    msgbox_EmailParserConfig(
      where: {
        ParserType: { _eq: $parserType }
        TemplateId: { _eq: $templateId }
        EmailAddress: { _eq: $emailAddress }
      }
    ) {
      Mapping
    }
  }
`;

export const UPDATE_EMAIL_PARSER_CONFIG = gql`
  mutation MyMutation(
    $apiAccountId: Int!
    $emailAddress: String!
    $templateId: Int
    $parserType: String
    $forwardingEmail: String
    $doNotForwardIfDeliveredOk: Boolean
    $doNotForwardIfReadOK: Boolean
    $doNotForwardIfSentOK: Boolean
    $doNotForwardIfRepliedTo: Boolean
    $mapping: json
  ) {
    update_msgbox_EmailParserConfig(
      where: {
        APIAccountId: { _eq: $apiAccountId }
        EmailAddress: { _eq: $emailAddress }
      }
      _set: {
        TemplateId: $templateId
        ParserType: $parserType
        ForwardingEmail: $forwardingEmail
        DoNotForwardIfDeliveredOK: $doNotForwardIfDeliveredOk
        DoNotForwardIfReadOK: $doNotForwardIfReadOK
        DoNotForwardIfSentOK: $doNotForwardIfSentOK
        DoNotForwardIfRepliedTo: $doNotForwardIfRepliedTo
        Mapping: $mapping
      }
    ) {
      affected_rows
      returning {
        TemplateId
        ParserType
        ForwardingEmail
      }
    }
  }
`;
