import MetaTags from "react-meta-tags";
import React, { useContext } from "react";

import { Row, Col, Alert, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { loginUser } from "../../slices/thunks";

//context
import { ThemeContext } from "src/helpers/themeContext";

interface LoginProps {
  history: any;
}

const Login = ({ history }: LoginProps) => {
  const dispatch = useDispatch();

  const { error } = useSelector((state: any) => ({
    error: state.login.error,
  }));

  // handleValidSubmit
  const handleValidSubmit = (event: any, values: any) => {
    dispatch(loginUser(values, history));
  };

  const theme = useContext(ThemeContext);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | {theme.name} reseller admin</title>
      </MetaTags>
      <div
        className="authentication-bg min-vh-100"
        style={{ backgroundImage: `url(${theme.images.authBackground})` }}
      >
        <div className="bg-overlay bg-white"></div>
        <Container>
          <div className="d-flex flex-column min-vh-100 px-3 pt-4">
            <Row className="justify-content-center my-auto">
              <Col md={8} lg={6} xl={4}>
                <div className="py-5">
                  <div className="mb-4 mb-md-5 text-center">
                    <Link to="/sales" className="d-block auth-logo">
                      <img
                        src={theme.images.logo}
                        alt=""
                        width="100%"
                        className="auth-logo-light"
                      />
                    </Link>
                  </div>
                  <div className="text-center mb-4">
                    <h5>Welcome Back !</h5>
                    <p className="text-muted">Sign in to {theme.name} admin.</p>
                  </div>
                  <AvForm
                    onValidSubmit={(e: any, v: any) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    <div className="form-floating form-floating-custom mb-3">
                      <AvField
                        name="email"
                        label="Email"
                        value=""
                        className=""
                        placeholder="Enter email"
                        type="email"
                        required
                      />
                    </div>
                    <div className="form-floating form-floating-custom mb-3">
                      <AvField
                        name="password"
                        label="password"
                        value=""
                        type="password"
                        className=""
                        required
                        placeholder="Enter Password"
                      />
                    </div>
                    <div className="form-check form-check-info font-size-16">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="remember-check"
                      />
                      <label
                        className="form-check-label font-size-14 text-muted"
                        htmlFor="remember-check"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="mt-3">
                      <button className="btn btn-light w-100" type="submit">
                        Log In
                      </button>
                    </div>
                  </AvForm>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl="12">
                <div className="text-center text-muted p-4">
                  <p className="mb-0"></p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
