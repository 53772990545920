 export const parserList = [
    {"cars2buy": {
        "1": "extensionData.leadSource",
        "2": "contact.name",
        "3": "contact.email",
        "4": "extensionData.make",
        "5": "extensionData.model",
        "6": "extensionData.derivative",
        "7": "extensionData.monthlyPrice"
      }
    },
    {"smartai": {
        "1": "extensionData.leadSource",
        "2": "contact.name",
        "3": "extensionData.phoneNumber",
        "4": "extensionData.email",
        "5": "extensionData.propertyAddress",
        "6": "extensionData.comments",
        "7": "extensionData.make",
        "8": "extensionData.model",
    }},
    
      {"fakeParser": {
        "1": "contact.name",
        "2": "extensionData.propertyAddress",
        "3": "extensionData.saleprice"
      }},
    
      {"hubspot": {
        "1": "contact.name"
      }},
    
      {"jaksinstantvaluation": {
        "1": "contact.name",
        "2": "extensionData.propertyAddress",
        "3": "extensionData.saleprice"
      }},
    
      {"leadpro": {
        "1": "contact.name",
        "2": "extensionData.propertyAddress",
        "3": "extensionData.saleprice"
      }},
    
      {"logicmelon": {
        "1": "contact.name",
        "2": "extensionData.jobDesc"
      }},
    
      {"onthemarket": {
        "1": "contact.name",
        "2": "extensionData.propDescription (fallback: 'property')",
        "3": "extensionData.leadSource",
        "4": "extensionData.url"
      }},
    
      {"rightmove": {
        "1": "contact.name",
        "2": "extensionData.propDescription (fallback: 'property')",
        "3": "extensionData.phoneNumber",
        "4": "extensionData.propertyReference",
        "5": "extensionData.leadSource",
        "6": "extensionData.url",
        "7": "extensionData.propertyAddress",
        "8": "extensionData.comments",
        "9": "extensionData.email",
      }},
    
      {"rightmovevaluation": {
        "1": "contact.name",
        "2": "extensionData.email",
        "3": "extensionData.propDescription"
      }},
    
      {"totaljobs": {
        "1": "contact.name",
        "2": "extensionData.jobDesc (fallback: 'the job')"
      }},
    
      {"typeform": {
        "1": "contact.name (conditional)"
      }},
    
      {"valpal": {
        "1": "contact.name",
        "2": "extensionData.propertyAddress",
        "3": "extensionData.saleprice",
        "4": "extensionData.rentalPrice"
      }},
    
      {"valpalv2": {
        "1": "contact.name",
        "2": "extensionData.propertyAddress",
        "3": "dynamic text about sales/rental figures (depends on extensionData.saleprice and extensionData.rentalPrice)",
        "4": "dynamic text about letters (L or S) depending on which valuations exist"
      }},
    
      {"vipvoip": {
        "1": "firstName extracted from contact.name"
      }},
    
      {"wrightresidential": {
        "1": "contact.name"
      }},
    
      {"zoopla": {
        "1": "contact.name",
        "2": "extensionData.propDescription (fallback: 'property')",
        "3": "extensionData.leadSource",
        "4": "extensionData.url"
      }}    
  ]
  