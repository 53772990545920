import { gql } from "@apollo/client";

export const GET_EMAILPARSERS = gql`
  query GetEmailParsers {
    msgbox_EmailParserConfig(order_by: { EmailParserConfigId: desc }) {
      EmailParserConfigId
      DoNotForwardIfDeliveredOK
      DoNotForwardIfReadOK
      DoNotForwardIfRepliedTo
      DoNotForwardIfSentOK
      EmailAddress
      ForwardingEmail
      ParserType
      Organisation {
        Name
      }
      Template {
        Name
        IsDeleted
        Status
      }
      TemplateId
      APIAccount {
        Name
        APIAccountId
      }
    }
  }
`;
