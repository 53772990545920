import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { useQuery } from "@apollo/client";
import { GET_EMAILFORWARDERS } from "./emailforwarders.query";
import AddEmailForwarder from "src/components/AddEmailForwarder/AddEmailForwarder";
import EditEmailForwarder from "src/components/EditEmailForwarder/EditEmailForwarder"

const EmailForwarders = () => {
  const [tableData, setTableData] = useState([]);
  const [emailForwarderModal, setEmailForwarderModal] = useState(false);
  const [editEmailForwarderModal, setEditEmailForwarderModal] = useState(false);
  const [selectedEmailForwarder, setSelectedEmailForwarder] = useState(false)
  const columns = [
    {
      dataField: "Id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "OrgName",
      text: "Org Name",
      sort: true,
    },
    {
      dataField: "APIAccountName",
      text: "API Name",
      sort: true,
    },
    {
      dataField: "EmailAddress",
      text: "Outoing Email Address",
      sort: true,
    },
    {
      dataField: "RegEx",
      text: "Match Expression",
      sort: true,
    },
    {
      dataField: "IsDefault",
      text: "IsDefault",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, row: any) => (
        <React.Fragment>
          <UncontrolledDropdown>
            <DropdownToggle tag="button" className="btn btn-light btn-sm">
              <i className="uil uil-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem onClick={() => {
                  onEdit(row)
                }
                }>
                  <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
               Edit
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </React.Fragment>
      ),
    }
  ];

  const onEdit = (row:any)=>{
    setEditEmailForwarderModal(!editEmailForwarderModal)  
    setSelectedEmailForwarder(row)
  }

  const defaultSorted: any = [
    {
      dataField: "id",
      order: "asc",
    },
  ];
  const selectRow: any = {
    mode: "checkbox",
  };
  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  };
  const { SearchBar } = Search;

  const { data, refetch } = useQuery(GET_EMAILFORWARDERS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data && data.msgbox_EmailParserOutgoingEmail) {
      var tmpData = data.msgbox_EmailParserOutgoingEmail.filter((item: any) => item.Organisation).map(function (item: {
        EmailParserOutgoingEmailId: number;
        EmailAddress: string;
        RegEx: string;
        IsDefault: boolean;
        Organisation: { Name: string };
        APIAccount: { Name: string };
        APIAccountId: number
      }) {
        return {
          Id: item.EmailParserOutgoingEmailId,
          APIAccountName: item.APIAccount?.Name,
          OrgName: item.Organisation.Name,
          EmailAddress: item.EmailAddress,
          IsDefault: item.IsDefault,
          RegEx: item.RegEx,
          APIAccountId: item.APIAccountId
        };
      });
      setTableData(tmpData);
    }
  }, [data]);

  const toggleAddEmailForwarderModal = () => {
    setEmailForwarderModal(!emailForwarderModal);
  };

  const handleUserClicks = () => {
    toggleAddEmailForwarderModal();
  };

  const refetchData = () => {
    refetch();
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <h4 className="card-title">All Email Forwarders</h4>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                <div>
                  <Link to="#" className="btn btn-light" onClick={refetchData}>
                    <i className="uil uil-refresh me-1"></i> Refresh
                  </Link>
                </div>
                <div>
                  <Link
                    to="#"
                    className="btn btn-light"
                    onClick={handleUserClicks}
                  >
                    <i className="uil uil-plus me-1"></i> Add New
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            // columns={columns}
            // data={productData}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="Id"
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            // responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={selectRow}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                          <EditEmailForwarder
                          modal={editEmailForwarderModal}
                          setModal={setEditEmailForwarderModal}
                          data={selectedEmailForwarder}
                          refresh={refetchData}
                          />
                          <AddEmailForwarder
                            modal={emailForwarderModal}
                            setModal={setEmailForwarderModal}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default EmailForwarders;
