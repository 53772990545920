import axios from "axios";
import * as url from "./url_helper";
import { ApiCore } from "./api_helper";

const api = new ApiCore();
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const postJwtLogin = (data: any) => {
  return api.create(url.POST_FAKE_JWT_LOGIN, data);
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtLogin
};
