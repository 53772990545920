import { gql } from "@apollo/client";

export const GET_ORGANISATIONS = gql`
  query MyQuery {
    msgbox_Organisation(order_by: { Name: asc }) {
      OrganisationId
      Name
    }
  }
`;

export const GET_API_ACCOUNTS = gql`
  query MyQuery($orgId: Int) {
    msgbox_APIAccount(
      where: { OrganisationId: { _eq: $orgId } }
      order_by: { Name: asc }
    ) {
      APIAccountId
      Name
    }
  }
`;

export const GET_TEMPLATES = gql`
  query MyQuery($apiAccountId: Int!) {
    msgbox_Template(
      where: {
        APIAccount: { _eq: $apiAccountId }
        Status: { _eq: Approved }
        IsDeleted: { _eq: false }
      }
      order_by: { Name: asc }
    ) {
      Name
      TemplateId
      TemplateText
    }
  }
`;

export const GET_PARSERTYPES = gql`
  query MyQuery {
    msgbox_EmailParserType {
      Name
    }
  }
`;

export const GET_MAPPING = gql`
  query MyQuery(
    $parserType: String!
    $templateId: Int!
    $emailAddress: String!
  ) {
    msgbox_EmailParserConfig(
      where: {
        ParserType: { _eq: $parserType }
        TemplateId: { _eq: $templateId }
        EmailAddress: { _eq: $emailAddress }
      }
    ) {
      Mapping
    }
  }
`;

export const INSERT_MAPPING = gql`
  mutation MyMutation(
    $apiAccountId: Int!
    $emailAddress: String!
    $mapping: json
    $parserType: String!
    $templateId: Int!
    $orgId: Int!
    $selectedForwardEmail: String
    $doNotForwardIfRepliedTo: Boolean
    $doNotForwardIfSentOK: Boolean
    $doNotForwardIfDeliveredOK: Boolean
    $doNotForwardIfReadOK: Boolean
  ) {
    insert_msgbox_EmailParserConfig(
      objects: [
        {
          APIAccountId: $apiAccountId
          EmailAddress: $emailAddress
          Mapping: $mapping
          ParserType: $parserType
          TemplateId: $templateId
          OrganisationId: $orgId
          ForwardingEmail: $selectedForwardEmail
          DoNotForwardIfRepliedTo: $doNotForwardIfRepliedTo
          DoNotForwardIfSentOK: $doNotForwardIfSentOK
          DoNotForwardIfDeliveredOK: $doNotForwardIfDeliveredOK
          DoNotForwardIfReadOK: $doNotForwardIfReadOK
        }
      ]
    ) {
      affected_rows
      returning {
        Mapping
      }
    }
  }
`;
