import React from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Col, Container, Row } from "reactstrap";
import Customers from './Customers';

const Sales = () => {

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | Customers Dashboard</title>
                </MetaTags>

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Dashboards" breadcrumbItem="Customers" />

                    <Row>
                        <Col xl={8}>
                            <Customers />
                        </Col>

                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default Sales;