import { combineReducers } from 'redux'
import layoutReducer from "./Layout/reducer"

//login
import loginReducer from "./Auth/Login/reducer";

// // User Profile 
import profileReducer from "./Auth/Profile/reducer";

const rootReducer = combineReducers({
    Layout: layoutReducer,
    login: loginReducer,
    profile: profileReducer,
})

export default rootReducer