import { Redirect } from "react-router-dom";

//Sales
import Sales from "../pages/Sales";
import Customers from "../pages/Customers";
//Analytics
import Analytics from "../pages/Analytics";

//Authentication pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// msgboxx specific
import OrganisationsPage from "../pages/Organisations";
import UsersPage from "../pages/Users";
import InboxesPage from "../pages/Inboxes";
import TemplatesPage from "src/pages/Templates";
import MessagesPage from "src/pages/Messages";
import EmailParserLogsPage from "src/pages/EmailParserLogs";
import EmailParsersPage from "src/pages/EmailParsers";
import EmailForwardersPage from "src/pages/EmailForwarders";
import BotResponsesPage from "src/pages/BotResponses";
import WidgetPage from "src/pages/WebWidget";
import TeamsPage from "src/pages/Teams";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  //msgboxx
  { path: "/organisations", component: OrganisationsPage },
  { path: "/users", component: UsersPage },
  { path: "/inboxes", component: InboxesPage },
  { path: "/templates", component: TemplatesPage },
  { path: "/messages", component: MessagesPage },
  { path: "/emailparsers", component: EmailParsersPage },
  { path: "/emailforwarders", component: EmailForwardersPage },
  { path: "/botresponses", component: BotResponsesPage },
  { path: "/widgets", component: WidgetPage },
  { path: "/teams", component: TeamsPage },

  //dashboard
  { path: "/customers", component: Customers },
  { path: "/sales", component: Sales },
  { path: "/dashboards-analytics", component: Analytics },

  //logs
  { path: "/emailparserlogs", component: EmailParserLogsPage },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/sales" /> },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
];

export { userRoutes, authRoutes };
