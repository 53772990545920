import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useContext,
} from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { ThemeContext } from "src/helpers/themeContext";
import {
  GET_ORGANISATIONS,
  GET_API_ACCOUNTS,
  GET_TEMPLATES,
  GET_PARSERTYPES,
  GET_MAPPING,
  INSERT_MAPPING,
} from "./query";

import client from "../../apollo";
import SweetAlert from "react-bootstrap-sweetalert";
import { toNumber } from "lodash";
import {
  ICreateEmailParserProps,
  CreateEmailParser,
} from "src/common/emailParserUtility";
import CustomMappingMenu from "./CustomMappingMenu";
import { parserList } from "./parserList";
import { DocumentNode, parseType, valueFromAST } from "graphql";
import { useLazyQuery, useMutation } from "@apollo/client";
import EmailParser from "src/pages/Analytics/EmailParser/EmailParser";
import { get } from "http";
import { toast } from "react-hot-toast";

interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  inboxId?: number;
  orgName?: string;
  orgId?: number;
  apiAccountId?: number;
  tableData: any;
}

const AddEmailParser = (props: IProps) => {
  const handleShow = () => {
    props.setModal(false);
  };
  const toggle = () => {
    props.setModal(!props.modal);
  };
  const Theme = useContext(ThemeContext);
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);
  const [organisations, setOrganisations] = useState([]);
  const [apiAccounts, setApiAccounts] = useState([]);
  const [templates, setTemplates] = useState(["No Templates Available"]);
  const [parserTypes, setParserTypes] = useState([]);
  const [showApi, setShowApi] = useState<boolean>(false);
  const [showTemplate, setShowTemplate] = useState<boolean>(false);
  const [selectedOrg, setSelectedOrg] = useState<number>(-1);
  const [selectedApi, setSelectedApi] = useState<number>(-1);
  const [selectedTemplate, setSelectedTemplate] = useState<number>(-1);
  const [selectedParserType, setSelectedParserType] = useState<any>("");
  const [selectedParserIndex, setSelectedParserIndex] = useState<number>(0);
  const [templateText, setTemplateText] = useState<string>("");
  const [templateVariableCount, setTemplateVariableCount] = useState<number>(0);
  const [mapData, setMapData] = useState<any[]>([]);
  const [editClicked, setEditClicked] = useState<boolean>(false);
  const [selectedForwardEmail, setSelectedForwardEmail] = useState<any>();
  const [selectedForwardOption, setSelectedForwardOption] = useState<any>(1);
  const [doneClicked, setDoneClicked] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
  const [emailDuplicateError, setEmailDuplicateError] =
    useState<boolean>(false);
  const [noTemplateSelectedError, setNoTemplateSelectedError] =
    useState<boolean>(false);

  const [customMappingsModal, setCustomMappingsModal] =
    useState<boolean>(false);
  const [customMappingOptions, setCustomMappingOptions] = useState<any>();
  const [addedCustomMaps, setAddedCustomMaps] = useState<any>([
    { key: 1, value: "" },
  ]);
  const [selectedEmailAddress, setSelectedEmailAddress] = useState<string>("");

  const [saved, setSaved] = useState<boolean>(false);

  const [insertMapping] = useMutation(INSERT_MAPPING);

  console.log(props.tableData, "<< table data in add");

  const [getMapping, { data: InboxData }] = useLazyQuery(GET_MAPPING, {
    variables: {
      parserType: selectedParserType,
      templateId: selectedTemplate,
      emailAddress: selectedEmailAddress,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (organisations.length === 0) {
      loadOrgs();
    }
    loadParserTypes();
  }, []);

  useEffect(() => {
    if (selectedParserIndex !== -1 && selectedTemplate !== -1) {
      handleCustomMappings();
    }
  }, [selectedParserIndex, selectedTemplate, selectedApi]);

  useEffect(() => {
    if (apiAccounts && apiAccounts[0]) {
      handleApiSelect(apiAccounts[0]["APIAccountId"]);
    }
  }, [apiAccounts]);

  const handleCustomMappings = async () => {
    const match: any = templates.find((template: any) => {
      return template.TemplateId === selectedTemplate;
    });

    const regex: RegExp = /\{\{(\d+)\}\}/g;
    let regXMatch: RegExpExecArray | null;
    const results: object[] = [];

    while ((regXMatch = regex.exec(match.TemplateText)) !== null) {
      results.push({ key: regXMatch[1], value: "" });
    }

    setTemplateVariableCount(results.length);

    setTemplateText(match.TemplateText);

    const optionsMatch = parserList.find(
      parser => Object.keys(parser)[0] === selectedParserType.toLowerCase()
    );

    const optionsArray: string[] = [];
    if (optionsMatch) {
      Object.entries(optionsMatch).forEach(([key, value]) => {
        if (typeof value === "object" && value !== null) {
          Object.entries(value).forEach(([nestedKey, nestedValue]) => {
            if (typeof nestedValue === "string") {
              optionsArray.push(nestedValue);
            }
          });
        }
      });
    }

    setCustomMappingOptions(optionsArray);
  };

  const loadOrgs = async () => {
    const orgs = await client.query({
      query: GET_ORGANISATIONS,
    });
    const orgData = orgs?.data?.msgbox_Organisation || [];
    const orgId = orgData[0]["OrganisationId"];

    setOrganisations(orgData);
    setSelectedOrg(orgId);

    if (orgData.length > 0) {
      loadApis(orgId);
    }
  };

  const loadParserTypes = async () => {
    const orgs = await client.query({
      query: GET_PARSERTYPES,
    });
    const parserTypes = orgs?.data?.msgbox_EmailParserType || [];

    setParserTypes(parserTypes);
    setSelectedParserType(parserTypes[0].Name);
  };

  const loadApis = async (orgId: number) => {
    const apis = await client.query({
      query: GET_API_ACCOUNTS,
      variables: {
        orgId: orgId,
      },
    });
    const apiData = apis?.data?.msgbox_APIAccount || [];
    setApiAccounts(apiData);

    if (apiData.length > 0) {
      const apiId = apiData[0]["APIAccountId"];
      setSelectedApi(apiId);
    } else {
    }
    setShowApi(true);
  };

  const loadTemplates = async (apiAccountId: number) => {
    const templates = await client.query({
      query: GET_TEMPLATES,
      variables: {
        apiAccountId: apiAccountId,
      },
    });
    const templateData = templates?.data?.msgbox_Template || [];
    setTemplates(templateData);
    if (templateData.length > 0) {
      // console.log("select first api account, more than 0");
      const templateId = templateData[0]["TemplateId"];
      setSelectedTemplate(templateId);
    } else {
      //console.log("only 0");
    }
    setShowTemplate(true);
  };

  const handleOrgSelect = async (target: number) => {
    //console.log("handleOrgSelect");
    setShowApi(false);
    setSelectedOrg(target);
    await loadApis(target);
    const selectedOrg = organisations.filter(
      (order: any, key: number) => order["OrganisationId"] == target
    );
  };

  const handleApiSelect = async (target: number) => {
    setSelectedApi(target);
    await loadTemplates(target);
    //const selectedApi = apiAccounts.filter(
    //  (order: any, key: number) => order["APIAccountId"] == target
    //);
  };

  console.log(noTemplateSelectedError, "<< no template error");

  const handleTemplateSelect = async (target: number) => {
    setSelectedTemplate(target);
  };

  const handleParserTypeSelect = async (target: any) => {
    setSelectedParserIndex(target.selectedIndex);
    setSelectedParserType(target.options[target.selectedIndex].text);
  };

  const handleModalOpen = () => {
    if (props.orgId) {
      handleOrgSelect(props.orgId);
    }
    if (props.apiAccountId) {
      handleApiSelect(props.apiAccountId);
    }
  };

  async function handleSave(addedCustomMaps: any) {
    const isDuplicate = props.tableData.some(
      (parser: any) => parser.EmailAddress === selectedEmailAddress
    );

    if (isDuplicate) {
      setEmailDuplicateError(true);
      return;
    }

    console.log(selectedTemplate, "<< selected template");

    if (selectedTemplate === -1) {
      setNoTemplateSelectedError(true);
      return;
    }

    setSaved(true);

    let doNotForwardIfRepliedTo = false;
    let doNotForwardIfSentOK = false;
    let doNotForwardIfDeliveredOK = false;
    let doNotForwardIfReadOK = false;

    switch (selectedForwardOption) {
      case "1":
        doNotForwardIfRepliedTo = false;
        doNotForwardIfSentOK = false;
        doNotForwardIfDeliveredOK = false;
        doNotForwardIfReadOK = false;
        break;
      case "2":
        doNotForwardIfRepliedTo = false;
        doNotForwardIfSentOK = true;
        doNotForwardIfDeliveredOK = false;
        doNotForwardIfReadOK = false;
        break;
      case "3":
        doNotForwardIfRepliedTo = false;
        doNotForwardIfSentOK = false;
        doNotForwardIfDeliveredOK = true;
        doNotForwardIfReadOK = false;
        break;
      case "4":
        doNotForwardIfRepliedTo = false;
        doNotForwardIfSentOK = false;
        doNotForwardIfDeliveredOK = false;
        doNotForwardIfReadOK = true;
        break;
      case "5":
        doNotForwardIfRepliedTo = true;
        doNotForwardIfSentOK = false;
        doNotForwardIfDeliveredOK = false;
        doNotForwardIfReadOK = false;
        break;
    }

    const transformedArray = addedCustomMaps.map(
      (item: { mode: any; value: any }) => ({ [item.mode]: item.value })
    );

    const feedback = await insertMapping({
      variables: {
        apiAccountId: selectedApi,
        emailAddress: selectedEmailAddress,
        mapping: transformedArray,
        parserType: selectedParserType,
        templateId: selectedTemplate,
        orgId: selectedOrg,
        selectedForwardEmail: selectedForwardEmail,
        doNotForwardIfRepliedTo,
        doNotForwardIfSentOK,
        doNotForwardIfDeliveredOK,
        doNotForwardIfReadOK,
      },
    });

    if (
      feedback?.data?.insert_msgbox_EmailParserConfig?.returning?.length > 0
    ) {
      toast.success("Added Successfully!");
    } else {
      toast.error("Failed to add data.");
    }

    setCustomMappingsModal(false);
    props.setModal(false);
  }

  return (
    <React.Fragment>
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={props.modal}
        toggle={toggle}
        size="lg"
        onOpened={() => handleModalOpen()}
      >
        <ModalHeader tag="h4">Add New Email Parser</ModalHeader>
        <ModalBody className="modelStyle">
          <AvForm>
            <Row form className="d-flex flex-row mb-3">
              <Col>
                <FormGroup className="mb-3">
                  <Label>Select an Organisation</Label>
                  <Input
                    className="avInput"
                    id="selectOrg"
                    name="selectOrg"
                    type="select"
                    value={selectedOrg ?? ""}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      handleOrgSelect(toNumber(e.currentTarget.value));
                    }}
                  >
                    {Array.isArray(organisations)
                      ? organisations.map((order: any, key: number) => (
                          <option key={key} value={order["OrganisationId"]}>
                            {order["Name"]}
                          </option>
                        ))
                      : null}
                  </Input>
                </FormGroup>

                {showApi && (
                  <FormGroup className="mb-3">
                    <Label>Select an API Account</Label>
                    <Input
                      className="avInput"
                      id="selectApi"
                      name="selectApi"
                      type="select"
                      value={selectedApi ?? ""}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        handleApiSelect(toNumber(e.currentTarget.value))
                      }
                    >
                      {Array.isArray(apiAccounts) && apiAccounts.length > 0 ? (
                        apiAccounts.map((order: any, key: number) => (
                          <option key={key} value={order["APIAccountId"]}>
                            {order["Name"]}
                          </option>
                        ))
                      ) : (
                        <p>No API Accounts Found</p>
                      )}
                    </Input>
                  </FormGroup>
                )}

                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="parserEmailAddress"
                    label="Incoming Email Address"
                    type="email"
                    placeholder="Incoming email address"
                    errorMessage="Invalid email address"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={(e: any) => {
                      setSelectedEmailAddress(e.target.value);
                    }}
                  />

                  {emailDuplicateError && (
                    <p style={{ color: "#F34E4E", fontSize: 13 }}>
                      Email address already in use
                    </p>
                  )}
                </div>

                {showTemplate && (
                  <FormGroup className="mb-3">
                    <Label>Select Template</Label>
                    <Input
                      className="avInput"
                      id="template"
                      name="template"
                      type="select"
                      value={selectedTemplate !== -1 ? selectedTemplate : ""}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        handleTemplateSelect(toNumber(e.currentTarget.value))
                      }
                    >
                      {/* Default option when no templates exist */}
                      <option value="" disabled>
                        Select Template
                      </option>

                      {Array.isArray(templates) &&
                        templates.length > 0 &&
                        templates.map((order: any, key: number) => (
                          <option key={key} value={order["TemplateId"]}>
                            {order["Name"]}
                          </option>
                        ))}
                    </Input>
                  </FormGroup>
                )}
                {noTemplateSelectedError && (
                  <p style={{ color: "#F34E4E", fontSize: 13 }}>
                    Select a template
                  </p>
                )}

                {parserTypes && (
                  <FormGroup
                    className="mb-3 flex flex-row"
                    style={{ display: "flex" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "70%",
                      }}
                    >
                      <Label>Select Parser Type</Label>
                      <Input
                        style={{ width: "100%" }}
                        className="avInput"
                        id="parserType"
                        name="parserType"
                        type="select"
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          handleParserTypeSelect(e.currentTarget);
                        }}
                      >
                        {Array.isArray(parserTypes) &&
                        parserTypes.length > 0 ? (
                          parserTypes.map((order: any, key: number) => (
                            <option key={key} value={key}>
                              {order["Name"]}
                            </option>
                          ))
                        ) : (
                          <p>No Parser Types Found</p>
                        )}
                      </Input>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "30%",
                        alignItems: "center",
                      }}
                    >
                      <Label>Enable Custom Mappings</Label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Input
                          type="checkbox"
                          style={{ width: 30, height: 30 }}
                          onChange={() => {
                            if (checkboxChecked === false) {
                              setCustomMappingsModal(true);
                              setCheckboxChecked(true);
                            } else {
                              setCheckboxChecked(false);
                            }
                          }}
                          checked={checkboxChecked}
                          disabled={
                            selectedParserIndex === -1 ||
                            selectedTemplate === -1
                          }
                        />

                        {saved ? (
                          <p
                            onClick={() => {
                              setEditClicked(true),
                                setCustomMappingsModal(true);
                            }}
                            style={{
                              padding: 8,
                              cursor: "pointer",
                            }}
                          >
                            Edit
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </FormGroup>
                )}

                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="forwardEmailAddress"
                    label="Forwarding Email Address"
                    type="email"
                    placeholder="Forwarding email address"
                    errorMessage="Invalid email address"
                    validate={{
                      required: { value: false },
                    }}
                    value={selectedForwardEmail ?? ""}
                    onChange={(e: any) => {
                      setSelectedForwardEmail(e.target.value);
                    }}
                  />
                </div>

                <div className="mb-3">
                  <AvField
                    className="avInput"
                    type="select"
                    id="forwardOption"
                    value={selectedForwardOption ?? "1"}
                    name="forwardOption"
                    label="Forwarding Option"
                    onChange={(e: any) => {
                      setSelectedForwardOption(e.target.value);
                    }}
                  >
                    <option value="1">Always forward</option>
                    <option value="2">Dont forward if successfully sent</option>
                    <option value="3">Dont forward if delivered</option>
                    <option value="4">Dont forward if customer read</option>
                    <option value="5">Dont forward if customer replied</option>
                  </AvField>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={handleShow}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success save-user"
                    onClick={() => handleSave(addedCustomMaps)}
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

      {/* Edit custom mappings modal  */}

      <Modal size="lg" style={{ marginTop: 200 }} isOpen={customMappingsModal}>
        <div style={{ width: "100%", height: "100%" }}>
          <ModalBody>
            <ModalHeader tag="h4">Email Parser Config</ModalHeader>
            <p style={{ margin: 15 }}>
              Add custom mappings for your email parser below:
            </p>

            <CustomMappingMenu
              addedCustomMaps={addedCustomMaps}
              setAddedCustomMaps={setAddedCustomMaps}
              customMappingOptions={customMappingOptions}
              templateText={templateText}
              templateVariableCount={templateVariableCount}
              mapData={mapData}
              editClicked={editClicked}
            ></CustomMappingMenu>
          </ModalBody>

          <div
            style={{
              position: "relative",
              bottom: 10,
              display: "flex",
              justifyContent: "end",
              margin: 15,
            }}
          >
            <button
              className="btn btn-success save-user m-2"
              onClick={() => {
                setSaved(true), setCustomMappingsModal(!customMappingsModal);
              }}
              style={{ width: "80px", height: "40px" }}
            >
              Done
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AddEmailParser;
