import React, { useEffect } from "react";
import { Label, Input } from "reactstrap";

interface IProps {
  customMappingOptions: string[];
  addedCustomMaps: any[];
  setAddedCustomMaps: any;
  templateText: string;
  templateVariableCount: number;
  mapData: any[];
  editClicked: boolean;
}

const CustomMappingMenu = (props: IProps) => {
  const {
    customMappingOptions,
    addedCustomMaps,
    setAddedCustomMaps,
    templateVariableCount,
    mapData,
    editClicked,
  } = props;

  useEffect(() => {
    if (mapData) {
      const initialMaps = mapData.map(item => ({
        value: Object.values(item)[0],
        mode: Object.keys(item)[0],
      }));
      setAddedCustomMaps(initialMaps);
    }
  }, [mapData, setAddedCustomMaps]);

  const handleModeChange = (index: number, mode: string) => {
    setAddedCustomMaps((prev: any) => {
      const newMaps = [...prev];
      newMaps[index] = { mode, value: "" };
      return newMaps;
    });
  };

  const handleValueChange = (index: number, value: string) => {
    setAddedCustomMaps((prev: any) => {
      const newMaps = [...prev];
      newMaps[index] = {
        mode: newMaps[index]?.mode || "variable",
        value,
      };
      return newMaps;
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <p style={{ margin: 15 }}>
        {props.templateText.replace(/{{(\d+)}}/g, (_, match) => {
          const index = parseInt(match, 10) - 1;
          const value = props.addedCustomMaps[index]?.value || match;
          return `{{${value}}}`;
        })}
      </p>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "18%",
          marginTop: 20,
          marginLeft: 15,
        }}
      >
        <Label>Position</Label>
        <Label>Static/Variable</Label>
        <Label>Value</Label>
      </div>

      {Array.from({ length: templateVariableCount }).map((_, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 20,
            alignItems: "center",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "15%" }}
          >
            <Input type="text" value={index + 1} readOnly />
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", width: "30%" }}
          >
            <Input
              type="select"
              value={addedCustomMaps[index]?.mode ?? "variable"}
              onChange={e => handleModeChange(index, e.target.value)}
            >
              <option value="variable">Variable</option>
              <option value="static">Static</option>
            </Input>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", width: "45%" }}
          >
            {addedCustomMaps[index]?.mode === "static" ? (
              <Input
                type="text"
                value={addedCustomMaps[index]?.value || ""}
                onChange={e => handleValueChange(index, e.target.value)}
                placeholder="Enter static text"
              />
            ) : (
              <Input
                type="select"
                value={addedCustomMaps[index]?.value ?? ""}
                onChange={e => handleValueChange(index, e.target.value)}
              >
                <option value="" disabled>
                  Select an option
                </option>
                {customMappingOptions.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Input>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CustomMappingMenu;
